.inputContainerStyles {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.labelTextStyles {
    font-family: "Switzer", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #555555;
}

.inputSubContainerStyles,
.focusInputStyles {
    max-width: 437px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    height: 48px;
    padding: 11px 24px;
    border: 1px solid #05050520;
    background: #FFFFFF;
}



.imageStyles {
    width: 24px;
    height: 24px;
}

.inputStyles {
    width: 100%;
    padding: 0px;
    border: none;
    outline: none;
    color: var(--black);
    font-family: "Switzer", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.02em;
    text-align: left;

}

.rightTextStyles,
.bottomTextStyles {
    font-family: "Switzer", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.02em;
    text-align: left;
    cursor: pointer;
}

.errorMsgTextStyles {
    color: red;
    font-family: "Switzer", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.02em;
    text-align: left;
    line-height: normal;
}