* {
  margin: 0;
  box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
::-ms-overflow {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* colors */
:root {
  --black: #000;
  --white: #ffffff;
  --charcoal: #050505;
  --lightGray: #efefef;
  --gray: #f1f1f1;
  --mid-Tone-Gray: #555555;
  --translucent-Charcoal: rgba(5, 5, 5, 0.63);
  --silver-Gray: rgba(239, 239, 239, 1);
  --pale-Lime-Green: rgba(212, 255, 183, 1);
  --lavender: rgba(250, 223, 254, 1);
  --bright-Cobalt-Blue: #004fff;
  --charcoal-100: #23252a;
  --charcoal-63: #23252a9f;
  --charcoal-18: #23252a30;
  --charcoal-37: #23252a60;
}

/* fonts */

@font-face {
  font-family: 'Switzer';
  src: url('../src/assets/fonts/Switzer-Variable.ttf');
}
@font-face {
  font-family: 'Inter Variable';
  src: url('../src/assets/fonts/InterVariable.ttf');
}
@font-face {
  font-family: 'Inter Display Light';
  src: url('../src/assets/fonts/InterDisplay-Light.woff2');
}
@font-face {
  font-family: 'Inter Display Regular';
  src: url('../src/assets/fonts/InterDisplay-Regular.woff2');
}
@font-face {
  font-family: 'Inter Display Medium';
  src: url('../src/assets/fonts/InterDisplay-Medium.woff2');
}
@font-face {
  font-family: 'Inter Display SemiBold';
  src: url('../src/assets/fonts/InterDisplay-SemiBold.woff2');
}
@font-face {
  font-family: 'Inter Display Bold';
  src: url('../src/assets/fonts/InterDisplay-Bold.woff2');
}
