.mainCardContainerStyles {
  max-width: 362px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  padding: 24px;
  border-radius: 24px;
  transition: 0.4s ease-in-out;
}

.mainCardContainerStyles:hover {
  padding: 24px;
  transform: scale(1.05);
  transition: 0.4s ease-in-out;
  transform-origin: center;
}

.trakrAssistCardContainerStyles {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.imageBlockStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border-radius: 48px;
  background-color: #05050510;
}

.topImageStyles {
  width: 24px;
  height: 24px;
}

.bottomBlockStyles {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.headingStyles {
  max-width: 314px;
  width: 100%;
  font-family: 'Switzer', sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.04em;
  text-align: left;
}

.optionsBlockStyles {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.optionsTextStyles {
  max-width: 314px;
  width: 100%;
  font-family: 'Switzer', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: left;
}

.leftImgBlockStyles,
.activeLeftImgBlockStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  width: 42px;
  height: 42px;
  border-radius: 24px;
  background-color: #05050510;
  cursor: pointer;
}

.activeLeftImgBlockStyles {
  background-color: #050505;
}

.leftImageStyles {
  width: 26px;
  height: 26px;
}

@media screen and (max-width: 800px) {
  .mainCardContainerStyles {
    max-width: 100%;
    padding: 16px;
  }
  .trakrAssistCardContainerStyles {
    gap: 16px;
  }
}

@media screen and (max-width: 560px) {
  .mainCardContainerStyles {
    max-width: 100%;
    padding: 20px;
    gap: 20px;
  }
  .trakrAssistCardContainerStyles {
    gap: 20px;
  }
}
