.footerContainerStyles {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 72px;
  padding: 48px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #05050520;
}

.topBlockStyles {
  max-width: 890px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.headingStyles {
  max-width: 490px;
  width: 100%;
  font-family: 'Switzer', sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.04em;
  text-align: center;
}

.descriptionTextStyles {
  max-width: 520px;
  width: 100%;
  font-family: 'Switzer', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: center;
}

.descriptionTextStyles span {
  color: #004fff;
  border-bottom: 1px solid #004fff;
  cursor: pointer;
}

.emailInputStyles {
  width: 437px;
  max-width: 100%;
  padding: 11px 24px;
  border-radius: 24px;
  border: 1px solid #05050520;
}

.bottomBlockStyles {
  max-width: 890px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.cotactUsSubBlockStyles {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.contactUsTextStyles {
  font-family: 'Switzer', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #050505;
}

/* Media Query Start */
@media screen and (max-width: 560px) {
  .footerContainerStyles {
    /* width: 100%; */
    gap: 32px;
    padding: 32px 16px;
  }
  .topBlockStyles {
    max-width: 100%;
  }
  .headingStyles {
    max-width: 380px;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
  }

  .descriptionTextStyles,
  .descriptionTextStyles span {
    max-width: 380px;
    font-size: 18px;
    line-height: 26px;
  }

  .emailInputStyles {
    width: 100%;
  }

  .emailInputStyles::placeholder {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.02em;
  }

  .bottomBlockStyles {
    flex-wrap: wrap;
    align-items: baseline;
  }

  .bottomBlockStyles .cotactUsSubBlockStyles:nth-child(4) {
    margin-top: 24px;
  }

  .contactUsTextStyles,
  .inputRightImgStyles {
    cursor: pointer;
  }

  .bottomBlockStyles {
    max-width: 890px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
  }

  .cotactUsSubBlockStyles {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }

  .contactUsTextStyles {
    font-family: 'Switzer', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #050505;
    cursor: pointer;
  }
}

/* Media Query End */
