.mainContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 24px;
}
.headerContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.headerTextStyle {
  font-family: 'Inter Display Regular', sans-serif;
  font-size: 64px;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: -0.032em;
  text-align: center;
  color: var(--charcoal-100);
}
.headerDescTextStyle {
  max-width: 604px;
  font-family: 'Inter Variable', sans-serif;
  font-size: 17.5px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: -0.032em;
  text-align: center;
  color: var(--charcoal-63);
}
.middleSectionStyle {
  max-width: 551px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.sectionContainerStyles {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 24px;
  padding: 24px;
  border: 1px solid var(--charcoal-18);
}
.sectionMeetContainerStyles {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 24px;
  padding: 24px;
  border: 1px solid var(--charcoal-18);
  cursor: pointer;
}
.meetContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.iconLeftContainerStyle {
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 12px;
}
.cardContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.iconContainerStyle {
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 12px;
  background-color: var(--gray);
}
.iconStyle {
  min-width: 24px;
  min-height: 24px;
}
.cardContentWrapperStyle {
  display: flex;
  flex-direction: column;
}
.cardHeaderTextStyle {
  font-family: 'Inter Display Regular', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.032em;
  text-align: left;
  color: var(--charcoal-100);
}
.cardDescTextStyle {
  font-family: 'Inter Variable', sans-serif;
  font-size: 17.5px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: -0.032em;
  text-align: left;
  color: var(--charcoal-63);
}
.labelTextStyle {
  font-family: 'Inter Variable', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.032em;
  text-align: left;
  color: var(--charcoal-63);
}
.inputsContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.inputStyle::placeholder {
  font-family: 'Inter Variable', sans-serif;
  font-size: 16.5px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: -0.032em;
  text-align: left;
  color: var(--charcoal-37);
}
.inputWithButtonStyles {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.fullNameInputSubStyles,
.textAreaModalStyles {
  max-width: 100%;
  border: 1px solid #dbe1ed;
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
}
.freeDemoTextStyle {
  font-family: 'Inter Variable', sans-serif;
  font-size: 15.5px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: -0.032em;
  text-align: left;
  color: var(--charcoal-63);
}
.clickHereTextStyle {
  font-family: 'Inter Variable', sans-serif;
  font-size: 15.5px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: -0.032em;
  text-align: left;
  color: var(--charcoal-100);
  text-decoration: underline;
  cursor: pointer;
}
.submitFormBtnStyles {
  background-color: var(--charcoal-100);
  width: 100%;
  align-items: center;
  justify-content: center;
}

.disableSubmitBtnStyles {
  background-color: var(--charcoal-100);
  opacity: 0.5;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.submitFormBtnTitleStyles {
  font-family: ' Inter Variable', sans-serif;
  font-size: 16.5px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.032em;
  text-align: left;
  color: var(--white);
}
.textAreaModalStyles {
  height: 100px;
  resize: none;
  font-family: 'Switzer', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.02em;
  outline: none;
}
.textAreaModalStyles::placeholder {
  width: 100%;
  padding: 0px;
  border: none;
  outline: none;
  color: var(--charcoal-37);
  font-family: 'Inter Variable', sans-serif;
  font-size: 16.5px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: -0.032em;
  text-align: left;
}
.mapContainerStyle {
  max-width: 503px;
  width: 100%;
  height: 288px;
  border-radius: 12px;
  border: 1px solid var(--charcoal-18);
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1133px) {
}
@media screen and (max-width: 986px) {
  .headerTextStyle {
    font-size: 48px;
    line-height: 56px;
  }
  .headerDescTextStyle {
    font-size: 14px;
    line-height: 18px;
  }
}
@media screen and (max-width: 686px) {
  .headerTextStyle {
    font-size: 32px;
    line-height: 40px;
  }
}
@media screen and (max-width: 480px) {
}
