.modalStyles {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: ease-in-out 0.5s;
  opacity: 1;
  height: 100%;
  width: 100%;
  visibility: visible;
  z-index: 1;
  background: rgba(0, 0, 0, 0.1);
}

.modalHideStyles {
  transition: ease-in-out 0.5s;
  opacity: 0;
  visibility: hidden;
}
@media screen and (max-width: 800px) {
  .modalStyles {
    padding: 0px 12px;
  }
}
