.headerContainerStyles {
  height: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 48px;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.trakrLogoStyles {
  width: 125px;
  height: 24px;
  cursor: pointer;
}

.middleMenuBlockStyles {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menuItemsStyles,
.activeMenuItemStyles {
  padding: 8.5px 16px;
  font-family: 'Switzer', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.02em;
  cursor: pointer;
  white-space: nowrap;
}

.activeMenuItemStyles {
  background-color: #efefef;
  border-radius: 12px;
}

.contactBtnStyles {
  padding: 8.5px 24px;
  width: 125px;
  height: 40px;
  white-space: nowrap;
  justify-content: center;
}

.hamberMenuBlockStyles {
  display: none;
}

/* Media Query Start */
@media screen and (max-width: 600px) {
  .headerContainerStyles {
    display: none;
  }

  .hamberMenuBlockStyles {
    display: flex;
    flex-direction: row;
    padding: 12px 16px;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
  }

  .mobileLogoStyles {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
  }

  .trakrImgStyle {
    object-fit: contain;
  }

  .hambergerMenuImgStyles {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .menuItemsBlockStyles {
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background-color: #fff;
  }

  .closeIconStyles {
    width: 24px;
    height: 24px;
    align-self: flex-end;
    cursor: pointer;
  }

  .mobileMenuItemsStyles,
  .mobileActiveMenuItemStyles {
    font-family: 'Switzer', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #555555;
    cursor: pointer;
  }

  .mobileActiveMenuItemStyles {
    color: #050505;
  }
}

@media screen and (max-width: 620px) {
  .menuItemsStyles,
  .activeMenuItemStyles {
    padding: 8.5px 10px;
  }
}

/* Media Query End */
