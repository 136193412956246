.btnStyles {
    display: flex;
    gap: 6px;
    align-items: center;
    width: fit-content;
    padding: 12px 24px;
    border-radius: 24px;
    border: 1px solid #05050520;
    background: #FFFFFF;
    cursor: pointer;
}

.btnImgStyles {
    width: 20px;
    height: 20px;
}

.imgStyles {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.btnTitleStyles {
    color: #050505;
    font-family: "Switzer", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.02em;
    text-align: left;
}

.svgIconStyles {
    width: 20px;
    height: 20px;
}