.headingStyles {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
    text-align: center;
    font-family: "Switzer", sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.04em;
}