/* --------------Desktop styles start----------------- */
.categoryContainerStyles {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  padding: 48px 0px;
}

.topSectionStyles {
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
  width: 100%;
}

.bannerContentStyles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  width: 100%;
  max-width: 890px;
}

.bannerTitleStyles {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.bannerHeaderStyles {
  font-family: 'Switzer', sans-serif;
  font-size: 72px;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: -0.04em;
  text-align: center;
  color: rgba(5, 5, 5, 1);
}
.headerTextStyle {
  font-family: 'Switzer', sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: -0.04em;
  text-align: center;
  color: rgba(5, 5, 5, 1);
  white-space: nowrap;
  word-break: keep-all;
}
.bannerDescpStyles {
  font-family: 'Switzer', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: center;
  color: rgba(85, 85, 85, 1);
  max-width: 525px;
  width: 100%;
  margin-inline: auto;
}

.bannerBtnStyles {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-inline: auto;
}

.activeBtnStyles {
  background: rgba(5, 5, 5, 1);
}

.activeBtnTitleStyles {
  color: rgba(255, 255, 255, 1);
  font-family: 'Switzer', sans-serif;
  font-weight: 500;
  line-height: 26px;
}

.inActiveBtnTitleStyles {
  font-family: 'Switzer', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: left;
}

.bannerImgContainerStyles {
  max-width: 1344px;
  max-height: 720px;
  padding: 0px 48px;
}

.bannerImgStyles {
  width: 100%;
  height: 100%;
}

/* styles.module.css */
.bannerBrandSectionStyles {
  width: 100%;
  background-color: #f1f1f1; /* Optional: for background styling */
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}

.scrollContainerStyle {
  display: flex;
  white-space: nowrap;
  width: 200%; /* Ensures there's enough space for the repeated items */
  animation: scrollText 10s linear infinite;
}

.brandBackGroundStyles {
  display: inline-block;
  padding: 0 20px; /* Adjust padding for spacing between items */
}

@keyframes scrollText {
  from {
    transform: translateX(0); /* Start from the beginning */
  }
  to {
    transform: translateX(-50%); /* Move to the middle of the container */
  }
}

.brandBackGroundStyles {
  width: fit-content;
  padding: 24px 48px;
  background-color: rgba(239, 239, 239, 0.25);
  border-radius: 12px;
}

.brandImageStyles {
  width: 100px;
  height: fit-content;
}

.brandLogoStyles {
  width: 100%;
  height: 100%;
}

.bottomBlockStyles {
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
}

.trakrAssitContentBlockStyles {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.trakrAssistHeadingStyles {
  max-width: 420px;
  width: 100%;
  font-family: 'Switzer', sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.04em;
  text-align: center;
}

.viewTrakrOfficeDropDownStyles {
  max-width: fit-content;
  width: 100%;
  align-self: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  padding: 8.5px 24px;
  border-radius: 32px;
  background-color: #05050510;
  cursor: pointer;
}

.viewTrakrOfficeTextStyles {
  font-family: 'Switzer', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: left;
}

.viewTrakrOfficeTextStyles span {
  font-family: 'Switzer', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: left;
}

.dropDownIconStyles {
  width: 16px;
  height: 16px;
}

.trakrAssistMainCardStyles {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.trakrAssistCardBlockStyles {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.manualAssetCardStyles {
  background-color: #efefef;
}

.saasBasedCardStyles {
  background-color: #d4ffb7;
}

.featuresCardStyles {
  background-color: #fadffe;
}

.moreTrakerDropDownStyles {
  max-width: 297px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 6px;
  background-color: #fff;
  border: 1px solid #05050520;
  padding: 12px 24px;
  align-items: center;
  align-self: center;
  border-radius: 24px;
  cursor: pointer;
}

.moreTrakrOfficeTextStyles {
  font-family: 'Switzer', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #050505;
  white-space: nowrap;
}

.rightArrowUpIconStyles {
  width: 26px;
  height: 26px;
}
.bookDemoModalStyles {
  background: rgba(0, 0, 0, 0.1);
}
.bookDemoContainerStyles {
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 48px 96px rgba(30, 30, 32, 0.08);
  margin: auto;
  padding: 32px;
}
.bookDemoCloseModalStyles {
  margin-left: auto;
  cursor: pointer;
}
.closeImageStyles {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modalTextStyles {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.modalTitleStyles {
  color: #000;
  font-family: 'Switzer', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
}
.modalTitleStyles span {
  color: #236bfe;
}
.modalDescpStyles {
  color: #000;
  font-family: 'Switzer', sans-serif;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.011em;
  line-height: 26px;
}
.modalDescpStyles span {
  color: #236bfe;
  text-decoration: underline;
}
.inputWithButtonStyles {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.inputModalStyles {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.fullNameInputSubStyles,
.textAreaModalStyles {
  max-width: 100%;
  border: 1px solid #dbe1ed;
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
}
.submitFormBtnStyles {
  background-color: #236bfe;
}

.disableSubmitBtnStyles {
  background-color: #236bfe;
  opacity: 0.5;
}

.submitFormBtnTitleStyles {
  color: #fff;
}
.textAreaModalStyles {
  height: 100px;
  resize: none;
  font-family: 'Switzer', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.02em;
  outline: none;
}
.textAreaModalStyles::placeholder {
  width: 100%;
  padding: 0px;
  border: none;
  outline: none;
  color: #555555;
  font-family: 'Switzer', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: left;
}
/* --------------Desktop styles end----------------- */

@media screen and (max-width: 1150px) {
  .bannerImgContainerStyles {
    padding: 0px 32px;
  }

  .bottomBlockStyles {
    padding: 0px 32px;
  }

  .trakrAssistCardBlockStyles {
    display: grid;
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 800px) {
  .categoryContainerStyles {
    gap: 24px;
    padding: 24px 0px;
  }

  .topSectionStyles {
    gap: 24px;
  }

  .bannerContentStyles {
    padding: 0px 24px;
    gap: 16px;
  }

  .bannerTitleStyles {
    gap: 8px;
  }

  .bannerImgContainerStyles {
    padding: 0px 24px;
  }

  .brandBackGroundStyles {
    padding: 12px;
  }

  .bottomBlockStyles {
    padding: 0px;
    gap: 24px;
    padding: 0px 24px;
  }

  .trakrAssistHeadingStyles,
  .bannerHeaderStyles {
    font-size: 32px;
    line-height: 40px;
    max-width: 100%;
  }
  .trakrAssistMainCardStyles {
    gap: 24px;
  }

  .trakrAssistCardBlockStyles {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 560px) {
  .categoryContainerStyles {
    padding: 16px 0px 0px;
    gap: 0px;
  }
  .topSectionStyles {
    gap: 32px;
    padding-bottom: 32px;
  }
  .bannerContentStyles {
    padding: 0px 16px;
  }
  .bannerHeaderStyles {
    font-size: 48px;
    line-height: 56px;
    text-align: center;
  }
  .bannerImgContainerStyles {
    padding: 0px 16px;
    max-height: 320px;
    height: 320px;
  }
  .brandBackGroundStyles {
    padding: 16px 32px;
  }
  .bottomBlockStyles {
    padding: 32px 16px;
    gap: 32px;
  }
  .trakrAssitContentBlockStyles {
    max-width: 382px;
    width: 100%;
    gap: 32px;
  }
}
